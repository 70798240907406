import { useRef, useState } from "react";
import { logoutUser } from "../redux/features/userAuth/userAuth";
import { useAppDispatch } from "../redux/app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { distributorlogout } from "../redux/features/distributoreCatalogue/distributoreCatalogue";
import ModalComponent from "../components/ModalComponent";
import ButtonComponent from "../components/ButtonComponent";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

type Props = {};

const ProfileDropDown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [cookies,] = useCookies(["type"]);

  const Modalref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(Modalref, () => setIsOpen(false));

  const { id } = useParams();

  const name = localStorage.getItem("name");
  const Number = localStorage.getItem("number");

  function handleLogout() {
    setDeleteModal(true);
  }

  function handlelogout() {
    if (cookies.type === "0") {
      dispatch(logoutUser()).then(() => {
        const Cookiess = new Cookies();
        Cookiess.remove("refreshToken", { path: "/" });
        Cookiess.remove("authToken", { path: "/" });
        Cookiess.remove("type", { path: "/" });
        Cookiess.remove("is_readOnly", { path: "/" });
        localStorage.clear();
        navigate(`/login?id=${id}`);
      });
    } else {
      dispatch(distributorlogout({})).then(() => {
        const Cookiess = new Cookies();
        Cookiess.remove("refreshToken", { path: "/" });
        Cookiess.remove("authToken", { path: "/" });
        Cookiess.remove("type", { path: "/" });
        Cookiess.remove("is_readOnly", { path: "/" });
        localStorage.clear();
        navigate(`/?id=${id}`);
  
      });
    }
  }
  return (
    <>
      {deleteModal ? (
        <ModalComponent setShow={setDeleteModal} show={deleteModal}>
          <div className="flex flex-col h-full  ">
            <div className="text-primary font-bold px-6 py-4 border-b flex flex-row justify-between">
              Logout
              <h1 className="text-lg font-bold cursor-pointer" onClick={() => setDeleteModal(false)}>
                X
              </h1>
            </div>
            <div className="min-h-[3rem] px-6 py-2">Are you sure for Logout?</div>
            <div className="flex flex-row justify-between px-6 gap-7 py-5">
              <ButtonComponent variant="primary" CTA="Cancel" onClick={() => setDeleteModal(false)} />
              <ButtonComponent variant="secondary" CTA="Log-Out" onClick={() => handlelogout()} />
            </div>
          </div>
        </ModalComponent>
      ) : null}
      <div className="flex flex-col">
        <div className="flex flex-row justify-end  items-center  gap-0 md:gap-2 relative" ref={Modalref} onClick={() => setIsOpen((prev) => !prev)}>
          <img className="hidden md:block " src="/profile.svg" alt="" />
          <p className="whitespace-nowrap overflow-hidden text-ellipsis w-14 md:w-full ">{name?.slice(0, 10)}</p>
          <img src="/profile dropdown.svg" className={isOpen === true ? "rotate-180" : "rotate-0"} alt="" />
          {isOpen === true ? (
            <div className="mt-2 absolute  border w-28  md:w-36 bg-white  top-8 flex gap-2 p-2 flex-col z-50 shadow-2xl rounded-md">
              <div className="flex flex-row w-full justify-between">
                <img src="/telephone.png " className="w-5 h-5 hidden md:block" alt="log" />
                <p className="bg-white cursor-pointer  ">{Number}</p>
              </div>
              <div className="flex flex-row justify-between">
                <img src="/profile.svg" className="w-5 hidden md:block" alt="log" />
                <p onClick={handleLogout} className="bg-white cursor-pointer">
                  Logout
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileDropDown;
