import React, { useEffect, useState } from "react";
import ButtonComponent from "./ButtonComponent";
import { useNavigate, useParams } from "react-router-dom";
import vkjLogo from "../assets/Logo/vkj logo.svg";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { addToCart, getAllCart } from "../redux/features/distributoreCart/distributoreCart";
import { useCookies } from "react-cookie";
type Props = {
  varient?: string;
  net_weight?: string;
  img?: string;
  categoryId: number;
  cartDetails: any;
  isSold: number;
  karat: number;
  varientNumber: string;
  availableStock: any;
};

const VarientCardComponent = ({ varient, net_weight, img, categoryId, cartDetails, isSold, karat, varientNumber, availableStock }: Props) => {
  const [imageSrc, setImageSrc] = React.useState(img);
  const [state, setState] = useState(true);
  const dispatch = useAppDispatch();
  const [cookies, setCookie] = useCookies(["is_readOnly"]);

  const handleImageError = () => {
    setImageSrc(vkjLogo);
  };
  useEffect(() => {
    if (cartDetails[0]?.quantity) {
      setState(false);
    }
  }, [cartDetails]);

  const { id } = useParams();
  function handelAddtoCart(categoryId: number) {
    dispatch(
      addToCart({
        distributor_catalogue_id: id,
        add_cart: [
          {
            cata_design_id: categoryId,
          },
        ],
      })
    ).then((res) => {
      if (res.payload.success) {
        setState(false);
      } else {
        setState(true);
      }
      dispatch(getAllCart());
    });
  }
  const navigate = useNavigate();
  return (
    <div className="col-span-3">
      <div className={`shadow-menu rounded-[10px] flex min-h-fit w-full flex-col px-8 py-2 ${isSold && availableStock <= 1 ? "bg-slate-200" : "bg-white"} `}>
        <div>
          <p></p>
          <div onClick={() => navigate(`/distributor/${id}/${varientNumber}/${categoryId}`)} className="py-4 ">
            <img className="w-full h-[250px]  object-contain" onError={handleImageError} src={imageSrc} alt={""} />{" "}
          </div>
          <p className="text-base">{varient}</p>
          <div className="flex flex-row gap-3 justify-between items-end">
            <h4 className="text-lg` font-bold">{net_weight} gms</h4>
            <h4 className="text-lg ">{karat}K</h4>
          </div>
          {cookies.is_readOnly === "0" ? (
            <>
              <div className="py-2">
                <div className="py-2">
                  <ButtonComponent onClick={isSold ? () => {} : () => handelAddtoCart(categoryId)} CTA={isSold ? "Sold Out" : state !== false ? `Add To Cart` : `In Cart`} />
                </div>
              </div>
            </>
          ) : null}
          
        </div>
      </div>
      <br/>
    </div>
    
  );
};

export default VarientCardComponent;
