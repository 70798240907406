import React, { useMemo, useState, useEffect, useRef } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import ArrowIcon from "./Icons/ArrowIcon";
import { nanoid } from "nanoid";

type Props = {
  asterisk: boolean;
  label: string;
  placeHolder: string;
  setFormValue: Function;
  countries: country[];
  register: Function;
  inputRef: string;
  value: string;
  isDisabled: boolean;
};

const MobileCodeDropDownComponent = ({ asterisk, label, placeHolder, countries, register, inputRef, isDisabled, setFormValue, value , }: Props) => {
  const [show, setshow] = useState(false);

  const Modalref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(Modalref, () => setshow(false));

  const [filterValue, setFilterValue] = useState("");

  const setFilterSearch = (filterValue: string) => {
    const searched = countries.filter((country) => (country.name.toLowerCase().includes(filterValue.toLowerCase())) || (country.phonecode.toLowerCase().includes(filterValue.toLowerCase())) );
    return searched;
  };
  const filteredCountries = useMemo(() => setFilterSearch(filterValue), [filterValue, countries]);

  const countryCode = useAppSelector((state) => state.userAuth.countryCode);
  return (
    <div className="w-full h-full">
      <label className="text-base font-bold text-primary" htmlFor="">
        {asterisk ? (
          <>
            {label}
            <span className="text-red-500"> *</span>
          </>
        ) : (
          label
        )}
      </label>
      <div className=" relative cursor-pointer border-b-[2px] border-b-primary-light gap-2 flex items-center">
        <div ref={Modalref}>
          <div
            className="mt-auto"
            onClick={
              !isDisabled
                ? () => {
                    setshow((prev) => !prev);
                  }
                : () => {}
            }
          >
            <div className="flex justify-center items-center gap-2">
              <span>{countryCode}</span>
              <ArrowIcon open={show} />
            </div>
          </div>
          {show ? (
            <div className="absolute max-w-[15rem] text-sm rounded-[10px] shadow-menu my-2 bg-white z-[99] ">
              <div className="flex justify-between px-2 border-2 rounded-md m-3">
                <input
                  type="text"
                  placeholder="Search Country"
                  className="outline-none rounded-md text-base"
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                  }}
                />
                <img className="w-[16px]" src="/search.svg" alt="" />
              </div>
              <ul className="max-h-64 max-w-xs overflow-auto ">
                {filteredCountries.map((i) => (
                  <li
                    className="hover:bg-primary-light px-4"
                    onClick={() => {
                      setFormValue(i);
                      setshow((prev) => !prev);
                    }}
                    key={nanoid()}
                  >{`${i.name}(${i.phonecode})`}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <span className="w-[1px] h-[1rem] bg-primary-light">{""}</span>
        <div className=" w-full flex flex-col">
          <input
            defaultValue={value}
            {...register(inputRef)}
            // onChange={(e) => {
            //   setFormValue(inputRef, e.target.value);
            // }}
            disabled={isDisabled}
            autoComplete="off"
            className="w-full outline-none text-base"
            placeholder={placeHolder}
            type="tel"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileCodeDropDownComponent;
