import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import FooterComponent from "../components/FooterComponent";
import NavbarComponent from "../components/NavbarComponent";

type Props = {
  children: React.ReactNode;
};

const OpenRoutes = ({ children }: Props) => {
  const [cookie, setCookie] = useCookies(["authToken", "refreshToken", "type"]);
 
  return (
    <>
      {" "}
      <NavbarComponent />
      {children}
      <FooterComponent />;
    </>
  );
};

export default OpenRoutes;
