import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../../api/api";

export const getAllCart = createAsyncThunk("distributoreCart/getAllCart", async () => {
  try {
    const res = await axiosClient.get("api/distcataloguecart");
    return res.data;
  } catch (err) {
    console.log(err);
  }
});

export const addToCart = createAsyncThunk("distributoreCart/addToCart", async (data: any) => {
  try {
    const res = await axiosClient.post("/api/distcataloguecart", data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});
export const removeAll = createAsyncThunk("distributoreCart/removeAll", async (id: any) => {
  try {
    const res = await axiosClient.delete(`/api/distcataloguecart/remove/all?distributor_catalogue_id=${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});
export const removeSelected = createAsyncThunk("distributoreCart/removeSelected", async (data: any) => {
  try {
    const res = await axiosClient.put("/api/distcataloguecart/remove/selected/", data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});
export const placeOrder = createAsyncThunk("distributoreCart/placeOrder", async (data: any) => {
  try {
    const res = await axiosClient.post("/api/distCatalogueOrder", data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});
const initialState: distributoreCart = {
  summary: {
    total_qty: 0,
    total_gross_weight: 0,
    total_net_weight: 0,
  },
  total_qty: 0,
  row: [],
  placeOrderData: {},
  placeOrderSoldData: {},
  ordernumber: "",
  user: {},
  distributore: {},
  date: "",
  soldErMes:""
};

const distributoreCart = createSlice({
  name: "distcataloguecart",
  initialState,
  reducers: {
    addQuantityCart: (state, { payload }) => {},
    updateCart: (state, { payload }) => {},
  },
  extraReducers(builder) {
    builder
      .addCase(getAllCart.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getAllCart.fulfilled, (state, { payload }) => {
        if (!Array.isArray(payload.data)) {
          return {
            ...state,
            status: "success",
            summary: payload.data.summary,
            row: payload.data.row,
          };
        } else {
          return {
            ...state,
            status: "success",
            summary: {
              total_qty: 0,
              total_gross_weight: 0,
              total_net_weight: 0,
            },
            row: [],
          };
        }
      })
      .addCase(getAllCart.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(addToCart.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(addToCart.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            status: "success",
          };
        }
      })
      .addCase(addToCart.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(removeAll.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(removeAll.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            status: "success",
          };
        }
      })
      .addCase(removeAll.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(removeSelected.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(removeSelected.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            status: "success",
          };
        }
      })
      .addCase(removeSelected.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(placeOrder.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(placeOrder.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            status: "success",
            placeOrderData: payload.data.orderDetails.DistCatalogueOrderDesigns,
            placeOrderSoldData: payload.data.alreadySoldDesigns,
            ordernumber: payload.data.orderDetails.order_number,
            user: payload.data.orderDetails.LeadUser,
            distributore: payload.data.orderDetails.distributor,
            date: payload.data.orderDetails.created_at,
            soldErMes: payload.message,
          };
        }
      })
      .addCase(placeOrder.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      });
  },
});

export const {} = distributoreCart.actions;

export default distributoreCart.reducer;
