import  { useState } from "react";
import TableComponent from "./TableComponent";

type Props = {
  list: LeadUserCartDesign[];
  name: string;
  totalQunty: number;
  totalwight: number;
  deleteModal: Function;
};

const AccordionComponent = ({ list, name, totalQunty, totalwight, deleteModal }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="flex flex-col my-8 rounded-lg justify-between border border-utils-line w-full">
      <div className="flex  w-full  p-7 justify-between font-bold text-xl cursor-pointer" onClick={() => setIsOpen((prev) => !prev)}>
        <h4 className="text-sm sm:text-lg">{name}</h4>
        <div className="flex flex-row">
          <h4 className="text-sm sm:text-lg">
            {totalQunty} Pcs | {totalwight} gm
          </h4>
          <img src="/dropdown.svg" alt="dropdwon" className="px-2" />
        </div>
      </div>

      {isOpen === true ? (
        <div className="overflow-auto hide-scroll">
          <TableComponent list={list} name={name} totalQunty={totalQunty} totalwight={totalwight} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AccordionComponent;
