import { useStateManager } from "react-select";
import { useAppSelector } from "../redux/app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

type Props = {};

const CartButton = (props: Props) => {
  const cart = useAppSelector((state) => state.leadUserCart.summary);
  const navigate = useNavigate();
  const { id } = useParams();
  const isSticky = useAppSelector((state) => state.userAuth.isSticky);
  return (
    <div
      className={`${
        isSticky ? "top-5" : "bottom-3"
      } fixed lg:relative h-[70px] lg:h-full   py-1 px-1 lg:bottom-auto lg:left-auto left-center-cart-menu  bg-primary rounded-[10px] z-10`}
    >
      <div className="flex items-center min-w-[330px] justify-between text-white">
        <div className="flex px-2">
          <img src="/cart.svg" alt="" />
          <div className="px-4">{cart.total_qty} items</div>
          <span className="w-[2px] bg-white"></span>
          <div className="px-4">{cart.total_net_weight} gm</div>
        </div>
        <div
          className="text-secondary cursor-pointer py-4 px-4 rounded-[10px] hover:text-primary hover:bg-secondary font-bold"
          onClick={() => navigate(`/catalogues/${id}/order-cart`)}
        >
          View Cart
        </div>
      </div>
    </div>
  );
};

export default CartButton;
