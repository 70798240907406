type Props = {
  searChnage: string;
  setSearChnage: Function;
};

const SearchBoxComponent = ({ searChnage, setSearChnage }: Props) => {
  return (
    <div className="flex flex-row border-b ">
      <input className="outline-none w-20 md:w-full"  type="text" value={searChnage} placeholder="Search" onChange={(e) => setSearChnage(e.target.value)} />
      <img src="/search.svg" alt="" />
    </div>
  );
};

export default SearchBoxComponent;
