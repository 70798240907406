import { useEffect, useState } from "react";
import ButtonComponent from "../../components/ButtonComponent";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useNavigate } from "react-router";
import { nanoid } from "nanoid";
import { useParams,  } from "react-router-dom";
import ModalComponent from "../../components/ModalComponent";
import backArrow from "../../assets/icons/up arrow.svg";
import { toastComponent } from "../../components/ToastComponent";
import DistributoreAcoordioinComp from "../../components/DistributoreAcoordioinComp";
import { getAllCart, placeOrder, removeAll } from "../../redux/features/distributoreCart/distributoreCart";
import DistributorePlaceOrder from "./DistributorePlaceOrder";

type Props = {};

const OrderSuccess = () => {
  return (
    <DistributorePlaceOrder/>
  );
};

const EmptyCart = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className="h-full m-auto">
      <div className="flex justify-center ">
        <img src="/empty cart image.png" alt="empty cart" />
      </div>
      <div className="text-center w-full  ">
        <h1 className="my-6 font-bold">Your Cart is Empty!</h1>
        <p className="my-6">Add items to it now.</p>
        <div className="">
          {" "}
          <ButtonComponent CTA="Shop Now" onClick={() => navigate(`/distributor/${id}`)} />
        </div>
      </div>
    </div>
  );
};

const DistributorOrder = (props: Props) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [placeOrderModel, setplaceOrderModel] = useState<boolean>(false);
  const cartDetails = useAppSelector((state) => state.distributoreCart.row);
  const cartOverView = useAppSelector((state) => state.distributoreCart.summary);
  const soldErMes = useAppSelector((state) => state.distributoreCart.soldErMes);
  const [orderSucces, setOrderSucces] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get the value of a specific query parameter

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCart());
  }, []);

  function handleRemoveall() {
    dispatch(removeAll(id)).then((res) => {
      toastComponent(res.payload.message);
      dispatch(getAllCart());
    });
    setDeleteModal(false);
  }
  function handleSubmit() {
    dispatch(placeOrder({ distributor_catalogue_id: id })).then((res:any) => {
      dispatch(getAllCart());
      setOrderSucces(true);
    
    }).catch(function (error) {
      toastComponent("All products in the cart are unavailable, and already sold out!");
    });
    setplaceOrderModel(false);
  }
  return (
    <>
      {deleteModal ? (
        <ModalComponent setShow={setDeleteModal} show={deleteModal}>
          <div className="flex flex-col h-full">
            <div className="text-primary font-bold px-6 py-4 border-b flex flex-row justify-between">
              Remove From the Cart ?
              <h1 className="text-lg font-bold cursor-pointer" onClick={() => setDeleteModal(false)}>
                X
              </h1>
            </div>
            <div className="min-h-[3rem] px-6 py-2">Are you sure to remove all designs from the cart ?</div>
            <div className="flex flex-row justify-between px-6 gap-7 py-3">
              <ButtonComponent variant="primary" CTA="Cancel" onClick={() => setDeleteModal(false)} />
              <ButtonComponent variant="secondary" CTA="Submit" onClick={() => handleRemoveall()} />
            </div>
          </div>
        </ModalComponent>
      ) : null}
      <div className={cartDetails.length ? "min-h-screen flex flex-col" : "min-h-[90vh] flex flex-col"}>
        {cartDetails.length ? (
          <>
            <div className="container mt-5">
              <button className="w-full text-left flex flex-row items-center my-1" onClick={() => navigate(`/distributor/${id}`)}>
                <img src={backArrow} alt="" className="-rotate-90  h-3 w-3" /> Back
              </button>
            </div>
            
            <div className="container">
              {cartDetails.map((i: any) => (
                <div key={nanoid()}>
                  <DistributoreAcoordioinComp total_qty={i.total_qty} total_net_weight={i.total_net_weight} name={i.name} list={i.list} />
                </div>
              ))}
            </div>
            <div></div>
            <div className="container gap-4 sm:grid grid-cols-12 text-base font-bold text-primary my-5">
              <div className="gap-2 w-full col-span-3 lg:col-span-2">
                <ButtonComponent CTA="Remove All" variant="danger" onClick={() => setDeleteModal(true)} />
              </div>
              <div className="sm:grid sm:grid-cols-12 col-span-9 lg:col-span-10  gap-2">
                <h5 className="col-span-8 lg:col-span-10 m-auto py-4 sm:py-0">{`Gross Wt: ${cartOverView.total_gross_weight} gms | Net Wt: ${cartOverView.total_net_weight} gms | pcs: ${cartOverView.total_qty}`}</h5>
                <span className="col-span-4 lg:col-span-2">
                  <ButtonComponent CTA="Place Order" onClick={() => setplaceOrderModel(true)} buttonType="submit" />
                </span>
              </div>
            </div>
          </>
        ) : (
          <>{orderSucces ? <OrderSuccess /> : <EmptyCart />}</>
        )}

        {placeOrderModel ? (
          <ModalComponent setShow={setplaceOrderModel} show={placeOrderModel}>
            <div className="flex flex-col h-full">
              <div className="text-primary font-bold px-6 py-4 border-b flex flex-row justify-between">
                Place Order From the Cart ?
                <h1 className="text-lg font-bold cursor-pointer" onClick={() => setplaceOrderModel(false)}>
                  X
                </h1>
              </div>
              <div className="min-h-[3rem] px-6 py-2">Are you sure for place order from the cart ?</div>
              <div className="flex flex-row justify-betwee px-6 gap-7 py-3">
                <ButtonComponent variant="primary" CTA="Cancel" onClick={() => setplaceOrderModel(false)} />
                <ButtonComponent variant="secondary" CTA="Submit" onClick={() => handleSubmit()} />
              </div>
            </div>
          </ModalComponent>
        ) : null}
      </div>
    </>
  );
};

export default DistributorOrder;
