import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../../api/api";

export const verifyLogin = createAsyncThunk("distributor/verify-login", async (id: any): Promise<any> => {
  try {
    const login = await axiosClient.get(`api/distributorCatalogue/checkCatalogue/${id}`);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});

export const verifyMobile = createAsyncThunk("distributor/verify-mobile", async (data: any): Promise<any> => {
  try {
    const verify = await axiosClient.post(`/api/distributorCatalogue/get-otp`, data);
    return verify.data;
  } catch (err) {
    console.log(err);
  }
});

export const distributoreLoginApi = createAsyncThunk("distributor/login", async (data: any): Promise<any> => {
  try {
    const login = await axiosClient.post(`/api/distributorCatalogue/login`, data);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});
export const distributoreSignUpApi = createAsyncThunk("distributor/signup", async (data: any): Promise<any> => {
  try {
    const login = await axiosClient.post(`/api/distributorCatalogue/signup`, data);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});

export const distributoreProfile = createAsyncThunk("distributor/profile", async (id: any): Promise<any> => {
  try {
    const distributoreProfile = await axiosClient.get(`/api/distributorCatalogue/distributor-profile/${id}`);
    return distributoreProfile.data.data;
  } catch (err) {
    console.log(err);
  }
});

export const distributorDesignList = createAsyncThunk("distributor/design-list", async (data: any): Promise<any> => {
  try {
    const res = await axiosClient.post(`/api/designCatalogue`, data.payload);
    return { res: res.data, isInfiniteScroll: data.isInfiniteScroll , currpage : data.payload.page };
  } catch (err) {
    console.log(err);
  }
});

export const distributorDesignFilter = createAsyncThunk("distributor/Desing-filter", async (id: any): Promise<any> => {
  try {
    const login = await axiosClient.get(`/api/designCatalogue/catalogue/dataforFilter?distributor_catalogue_id=${id}`);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});

export const distributorFilterListing = createAsyncThunk("distributor/design-filter-listing", async (data: any): Promise<any> => {
  try {
    const login = await axiosClient.post(`/api/designCatalogue`, data);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});

export const distributorVarientDetails = createAsyncThunk("distributor/variantMultiple", async (data: any): Promise<any> => {
  try {
    const res = await axiosClient.post(`/api/designCatalogue/variantMultiple`, data.payload);
    return { res: res.data, isInfiniteScroll: data.isInfiniteScroll };
  } catch (err) {
    console.log(err);
  }
});

export const distributorDetails = createAsyncThunk("distributor/details", async (data: any): Promise<any> => {
  try {
    const login = await axiosClient.get(`/api/designCatalogue/catalogue-design/${data}`);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});

export const distributorlogout = createAsyncThunk("distributor/details", async (data: any): Promise<any> => {
  try {
    const login = await axiosClient.post(`/api/distributorCatalogue/logout`, data);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});

const initialState: distributoreCatalogue = {
  loginVerfiyMess:"",
  searchFilter: "",
  varient_count: 0,
  is_next_page_varient: false,
  varient_rows: [],
  status: "success",
  mobileNumber: "",
  countryCode: "+91",
  OTPSent: false,
  client: {
    name: "",
    id: 1,
    email: "",
  },
  company: {
    id: 1,
    company_name: "",
    ImageURL: "",
  },
  clients: [],
  rows: [],
  category: [],
  categoryIds: [],
  karatIds : [],
  karat: [],
  page: 1,
  hasmore: true,
  totalcount : 0,
  varientHasmore: true,
  designDetails: {},
  catalaugename: "",
  catalugeitemes: 2,
  filtering : false,
  count: [
    {
      variant_number: "",
      count: 1,
    },
  ],
};

const distributoreCatalogue = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    updateCategoryId: (state, action) => {
      return {
        ...state,
        categoryid: action.payload,
      };
    },
    setSearchFilter: (state, action) => {
      return {
        ...state,
        searchFilter: action.payload,
      };
    },
    addItem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        categoryIds: [...state.categoryIds, Number(action.payload.id)], // add item to the array
      };
    },
    removeItem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        categoryIds: state.categoryIds.filter((item) => item !== Number(action.payload.id)), // remove item from the array by filtering
      };
    },
    addKaratItem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        karatIds: [...state.karatIds, action.payload.id], // add item to the array
      };
    },
    removeKaratItem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        karatIds: state.karatIds.filter((item) => item !== action.payload.id), // remove item from the array by filtering
      };
    },
    setMobileNumber: (state, action) => {
      return {
        ...state,
        mobileNumber: action.payload,
      };
    },
    setCountryCode: (state, action) => {
      return {
        ...state,
        countryCode: action.payload.countryCode,
        country_id: action.payload.id,
      };
    },
    setStateCode: (state, action) => {
      return {
        ...state,
        stateId: action.payload,
      };
    },
    setCityCode: (state, action) => {
      return {
        ...state,
        cityId: action.payload,
      };
    },
    setOTPstatus: (state, action) => {
      return {
        ...state,
        OTPSent: action.payload,
      };
    },
    updatekarat: (state, action) => {
      return {
        ...state,
        karat: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
    .addCase(verifyLogin.pending, (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    })
    .addCase(verifyLogin.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loginVerfiyMess:payload.message,
        loading: false,
      };
    })
    .addCase(verifyLogin.rejected, (state, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    })
      .addCase(verifyMobile.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(verifyMobile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          OTPSent: payload.success ? true : false,
        };
      })
      .addCase(verifyMobile.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributoreLoginApi.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(distributoreLoginApi.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributoreLoginApi.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributoreProfile.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(distributoreProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          client: payload.client,
          company: payload.Distributor.company,
          catalaugename: payload.name,
          catalugeitemes: payload.catalogueDesignsCount,
          loading: false,
        };
      })
      .addCase(distributoreProfile.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributorDesignList.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(distributorDesignList.fulfilled, (state, { payload }) => {
        if (payload.res.success) {
          return {
            ...state,
            hasmore: payload?.res?.data?.is_next_page === true ? true : false,
            rows:  payload.res.data.rows,
            page: payload.currpage,
            count: payload.res.count,
            totalcount : payload.res.data.count,
            loading: false,
          };
        } else {
          return {
            ...state,
            hasmore: false,
            rows: [],
            page: 1,
            count: [],
            totalcount : 0,
            loading: false,
          };
        }
      })
      .addCase(distributorDesignList.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributorDesignFilter.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(distributorDesignFilter.fulfilled, (state, { payload }) => {
        return {
          ...state,
          category: payload.data.category,
          categoryIds: payload.data.category.map((item: any) => item.category_id),
          karat : payload.data.karat,
          karatIds : payload.data.karat.map((item: any) => item.name),
          filtering : false,
          loading: false,
        };
      })
      .addCase(distributorDesignFilter.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributorFilterListing.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(distributorFilterListing.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributorFilterListing.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributorVarientDetails.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(distributorVarientDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          filtering : false,
          varient_count: payload.res.data.count,
          varientHasmore: payload.res.data.is_next_page,
          varient_rows: payload.res.data.rows,
        };
      })
      .addCase(distributorVarientDetails.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(distributorDetails.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(distributorDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          filtering : false,
          designDetails: payload.data,
        };
      })
      .addCase(distributorDetails.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export const {setSearchFilter, addKaratItem, removeKaratItem, setMobileNumber, setCountryCode, setOTPstatus, setStateCode, setCityCode, updatekarat, addItem, removeItem } = distributoreCatalogue.actions;

export default distributoreCatalogue.reducer;
