type Props = {
  Label: string;
  inputRef?: string;
  register?: Function;
};

const CheckBoxComponent = ({ Label = "label", inputRef = "val", register = () => {} }: Props) => {
  return (
    <div className="w-full flex gap-5 items-center justify-between">
      <label className="w-full">{Label}</label>
      <input className="flex w-full " type="checkbox" {...register(inputRef)} />
    </div>
  );
};

export default CheckBoxComponent;
