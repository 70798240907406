import React from "react";
import TabComponent from "../components/TabComponent";
import ButtonComponent from "../components/ButtonComponent";
import { CategoryFilter } from "./CategoryFilter";
import { KaratFilter } from "./KaratFilter";

type Props = {
  closeForm: Function;
  ref: any;
};

const FilterForm = ({ closeForm, ref }: Props) => {
  return (
    <div className=" flex min-h-[50vh] sm:min-h-[15vh] md:min-h-[25vh] justify-between z-[9999]  p-1 px-3 flex-col ">
      <div>
        <div className="h-full">
          <div className="my-3 flex justify-between w-full">
            <span ref={ref} className="text-base text-primary font-bold">
              Filter
            </span>
            <span className="flex gap-2">
              <span className="text-[#9E9E9E] font-black px-4" onClick={() => closeForm(false)}>
                X
              </span>
            </span>
          </div>
        </div>
        <div className="border-t h-full">
          <div className="flex gap-4 w-full sm:min-h-[17vh] md:min-h-[27vh] overflow-auto hide-scroll">
            {/* <TabComponent ComponentList={[CategoryFilter, KaratFilter]} TabIndex={["Category", "Karat"]} /> */}
            <TabComponent ComponentList={[CategoryFilter]} TabIndex={["Category"]} />
          </div>
        </div>
      </div>
      <div className="my-2">
        <ButtonComponent CTA="Apply Filter" onClick={() => closeForm(false)} />
      </div>
    </div>
  );
};

export default FilterForm;
