import React from "react";

type Props = {
  open: boolean;
};

const ArrowIcon = ({ open }: Props) => {
  return (
    <svg className={`${!open ? "rotate-180" : ""} transition`} xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 21.004 12.047">
      <path
        id="up_arrow"
        data-name="up arrow"
        d="M20,9.753a2.88,2.88,0,0,0-.746-.926Q15.025,4.6,10.793.372A1.028,1.028,0,0,0,9.484.144a1.368,1.368,0,0,0-.271.221Q4.789,4.786.364,9.206a1.011,1.011,0,0,0-.308,1.1.969.969,0,0,0,.8.681.945.945,0,0,0,.752-.192,2.321,2.321,0,0,0,.232-.209L9.8,2.638c.2-.2.2-.2.4-.007l7.994,7.986a1.03,1.03,0,0,0,1.34.225,1.232,1.232,0,0,0,.469-.66Z"
        transform="translate(0.504 0.521)"
        fill="#444"
        stroke="#444"
        strokeWidth="1"
      />
    </svg>
  );
};

export default ArrowIcon;
