import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { searchFilters, addKaratCattem, removeKaratCatItem } from "../redux/features/leadUserCatalogue/leadUserCatalogue";
import { nanoid } from "nanoid";
import ModalComponent from "../components/ModalComponent";
import ButtonComponent from "../components/ButtonComponent";
import { getAllCart, removeCartData } from "../redux/features/leadUserCart/leadUserCart";
import { toastComponent } from "../components/ToastComponent";

type Props = {
  postionFilter?: string;
  mobileView?: boolean; 
};

export const KaratFilter : React.FC<Props> = ({ postionFilter = "horizontal" , mobileView}) => {
  const dispatch = useAppDispatch();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  // const [karatFilter, setKaratFilter] = useState(22);
  const cartDetails = useAppSelector((state) => state.leadUserCart.row);
  const karatIds = useAppSelector((state) => state.leadUserCatalogue.karatid);
  const karatList = useAppSelector((state) => state.leadUserCatalogue.karat);

  function handleRemoveall() {
    dispatch(searchFilters(""));
    // changeKarat(karatFilter);
    setDeleteModal(false);
    const idArray: any = [];
    cartDetails.forEach((rowItem) => {
      rowItem.list.forEach((listItem) => {
        idArray.push(listItem.id);
      });
    });
    dispatch(removeCartData({ cart_id: idArray })).then((res) => {
      toastComponent(res.payload.message);
      dispatch(getAllCart());
    });
    setDeleteModal(false);
  }

  function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.checked) {
      if (karatIds.length !== 1) {
        dispatch(removeKaratCatItem({ id: e.target.value }));
      } else {
        toastComponent("Atleast 1 Karat Need");
      }
    } else {
      dispatch(addKaratCattem({ id: e.target.value }));
    }
  }

  return (
    <>
      {deleteModal ? (
        <ModalComponent setShow={setDeleteModal} show={deleteModal}>
          <div className="flex flex-col h-full">
            <div className="text-primary font-bold px-6 py-4 border-b flex flex-row justify-between">
              Confirm karat change ?
              <h1 className="text-lg font-bold cursor-pointer" onClick={() => setDeleteModal(false)}>
                X
              </h1>
            </div>
            <div className="min-h-[3rem] px-6 py-2">On karat change cart will be Empty ?</div>
            <div className="flex flex-row justify-betwee px-6 gap-7 py-3">
              <ButtonComponent variant="primary" CTA="Cancel" onClick={() => setDeleteModal(false)} />
              <ButtonComponent variant="secondary" CTA="Submit" onClick={() => handleRemoveall()} />
            </div>
          </div>
        </ModalComponent>
      ) : null}
      {
        mobileView ?  
      <div className="sm:float-right items-center gap-2 flex sm:mt-0">
        <span className="font-bold ">Karat </span>
        <div className="flex flex-row w-full gap-5">
        {karatList.map((i) => (
          <div className="cursor-not-allowed" key={nanoid()}>
            <label className={`border-secondary border py-1 px-1 rounded-md cursor-pointer  
              ${karatIds.findIndex((element: any) => element === i.name) === -1  ? "text-secondary " : "bg-secondary text-primary"}`}
            >
              <input
                type="checkbox"
                style={{ display: "none" }}
                value={i.name}
                onChange={(e) => handleClick(e)}
                checked={karatIds.findIndex((element: any) => element === i.name) === -1 ? false : true}
              />
              {i.name}
            </label>
          </div>
        ))}
        </div>
      </div>
        :  <div className={`${postionFilter === "horizontal" ? "flex flex-row gap-4" : "flex flex-col gap-2"} py-4`}>
        <ul className="cursor-pointer ">
          {karatList?.map((i: any) => (
            <li className="my-1" key={nanoid()}>
              <label className="flex gap-1 items-baseline ">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) => handleClick(e)}
                  value={i.name}
                  checked={karatIds.findIndex((element: any) => element === i.name) === -1 ? false : true}
                />
                <span style={{ marginLeft: "5px" }}>{i.name}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
      }
     
    </>
  );
};
