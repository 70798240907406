import Select from "react-select";
import { DropDownStyles } from "../helper";
import { Controller } from "react-hook-form";
import { useAppDispatch } from "../redux/app/hooks";

type Props = {
  asterisk?: boolean;
  placeHolder?: string;
  label?: string;
  data?: any[];
  setValue?: Function;
  control?: any;
  inputRef: string;
  isDisabled?: boolean;
  cataSelect?: boolean;
};
const SelectComponent = ({ label, placeHolder, asterisk, data, isDisabled = false, setValue = () => {}, control, inputRef, cataSelect=true }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col w-full">
      <Controller
        name={inputRef}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <>
            <label className="text-base font-bold text-primary" htmlFor="">
              {asterisk ? (
                <>
                  {label}
                  <span className="text-red-500"> *</span>
                </>
              ) : (
                label
              )}
            </label>
            <Select
              options={data}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              getOptionValue={(option: any) => String(option.id)}
              styles={DropDownStyles}
              placeholder={placeHolder}
              onChange={(e: any) => {
                onChange(e.id);
                if (cataSelect) {
                  dispatch(setValue(e.id));
                }
              }}
              isDisabled={isDisabled}
            />
          </>
        )}
        rules={{ required: true }}
      />
    </div>
  );
};

export default SelectComponent;
