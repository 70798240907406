import axios from "axios";
import { useEffect, useState } from "react";
import backArrow from "../../assets/icons/up arrow.svg";
import { Link, useNavigate } from "react-router-dom";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getdata();
  }, []);
  const [privacyData, setPrivacyData] = useState<any>([]);
  const nav = useNavigate();

  function getdata() {
    axios
      .post(`https://api-vkj.developmentlabs.co/api/cmspages/mobileapp/`, { keyList: ["TC", "PRIVACY"] })
      .then(function (response) {
        if (response.data.success === true) {
          setPrivacyData(response.data.data);
          return response;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <div className="container min-h-screen">
      <Link to={``} className="flex flex-row items-center pt-2" onClick={() => nav(-1)}>
        <img src={backArrow} alt="" className="-rotate-90 h-3 w-3" /> Back
      </Link>
      {privacyData.map((response: any, index: number) => (
        <>
          <h3 className="font-bold text-3xl py-2">{response?.name}</h3>
          <div className="py-2 cms-desc-pre" key={index}>
            {response?.description}
          </div>
        </>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
