import React, { useEffect } from "react";
import Layout from "../Layouts/Layout";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useCookies from "react-cookie/cjs/useCookies";
import { Cookies } from "react-cookie";
import { verifyLogin } from "../redux/features/userAuth/userAuth";
import { useAppDispatch } from "../redux/app/hooks";
import { toastComponent } from "../components/ToastComponent";

type Props = {
  children: React.ReactNode;
};

const LeadUserRoutes = ({ children }: Props) => {
  const [cookie, setCookie] = useCookies(["authToken", "refreshToken", "type", "is_readOnly"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();

  
  
  useEffect(() => {
    dispatch(verifyLogin(id)).then((res) => {
      if (res.payload.success) {
        if (cookie.type !== "0" && cookie.authToken) {
          const cookies = new Cookies();
          cookies.remove("refreshToken", { path: "/" });
          cookies.remove("authToken", { path: "/" });
          cookies.remove("type", { path: "/" });
          cookies.remove("is_readOnly", { path: "/" });
          navigate(`/error`);
        }
      } else {
        navigate(`/error`);
        toastComponent(res.payload.message);
      }
    });
    
  }, []);

  // useEffect(() => {
  //   if (cookie.type !== "0" && cookie.authToken) {
  //     const cookies = new Cookies();
  //     cookies.remove("refreshToken", { path: "/" });
  //     cookies.remove("authToken", { path: "/" });
  //     cookies.remove("type", { path: "/" });
  //     cookies.remove("is_readOnly", { path: "/" });
  //     navigate(`/error`);
  //   }
  // }, []);

  return (
    <>
      {cookie.authToken ? (
        <div>
          <Layout>{children}</Layout>
        </div>
      ) : (
        <Navigate to={`/login?id=${id}`} />
      )}
    </>
  );
};

export default LeadUserRoutes;
