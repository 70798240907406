import React, { useEffect } from "react";
import vkjLogo from "../../assets/Logo/vkj logo.svg";
import { useAppSelector, } from "../../redux/app/hooks";

type Props = {};

const Error404 = (props: Props) => {
  const dismes = useAppSelector((state) => state.distributoreCatalogue.loginVerfiyMess);
  const catalouge = useAppSelector((state) => state.userAuth.loginVerfiyMess);

  return (
    <div className="h-screen m-auto justify-center items-center flex flex-col">
      <div className="">
        <img src={vkjLogo} alt="empty cart" />
      </div>
      <div className="text-center w-full  ">
        <p className="my-6 font-bold text-2]xl">
          This catalogue link has been expired. If you want to see more, than download our app through below link.
          <br/><br/>
          <a className="gold-color-text" href="http://onelink.to/rht69t" target="_blank">Download App</a> 
        </p>
        {/* <p className="my-6 font-bold text-2]xl">{catalouge}</p> */}
      </div>
    </div>
  );
};

export default Error404;
