import { useEffect, useState } from "react";
import SelectComponent from "../../components/SelectComponent";
import InputComponent from "../../components/InputComponent";
import CheckBoxComponent from "../../components/CheckBoxComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toastComponent } from "../../components/ToastComponent";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { createleadPreference, getLeadPreference, placeOrder } from "../../redux/features/leadUserCart/leadUserCart";
import backArrow from "../../assets/icons/up arrow.svg";
import { nanoid } from "nanoid";

type Props = {};

const UserCatalogueForm = (props: Props) => {
  const [isView, setIsView] = useState(true);

  const screwTypeArr = [
    { id: 1, name: "Bombay Post with Screw" },
    { id: 2, name: "Bombay Post without Screw" },
    { id: 3, name: "South Screw" },
    { id: 4, name: "Push Butterfly Screw" },
  ];

  const navigate = useNavigate();

  const default_user_preference = useAppSelector((res) => res.leadUserCart.default_user_preference);
  const karatVal = useAppSelector((state) => state.leadUserCatalogue.karatid);
  const addedToCartArr = useAppSelector((state) => state.leadUserCart.row);
  const addedToCart = addedToCartArr[0]?.list[0]?.karat

  const schema = yup.object().shape({
    rhodium_on_stone_percentage: yup
      .number()
      // .required("lease enter a valid Rhodium on stone part")
      .typeError("Please enter a valid Rhodium on stone part")
      .min(0, "Percentage should be between 0 and 100")
      .max(100, "Percentage should be between 0 and 100")
      .nullable()
      .transform((_, val) => val === '' ? 0 : Number(val)),
    rhodium_on_plain_part_percentage: yup
      .number()
      // .required("Please enter a valid Rhodium on plain part")
      .typeError("Please enter a valid Rhodium on plain part")
      .min(0, "Percentage should be between 0 and 100")
      .max(100, "Percentage should be between 0 and 100")
      .nullable()
      .transform((_, val) => val === '' ? 0 : Number(val)),
    rhodium_remarks: yup
      .string()
      //.required()
      ,
    sandblasting_dull_percentage: yup
      .number()
      // .required()
      .typeError("Please enter a valid sandblasting dull percentage")
      .min(0, "Percentage should be between 0 and 100")
      .max(100, "Percentage should be between 0 and 100")
      .nullable()
      .transform((_, val) => val === '' ? 0 : Number(val)),
    satin_dull_percentage: yup
      .number()
      // .required()
      .typeError("Please enter a valid satin dull percentage")
      .min(0, "Percentage should be between 0 and 100")
      .max(100, "Percentage should be between 0 and 100")
      .test("dull_percentages_sum", "The sum of Sandblasting Dull and Satin Dull must be 100 or less", function (satin_dull_percentage: any) {
        const sandblasting_dull_percentage: any = this.resolve(yup.ref("sandblasting_dull_percentage"));
        const sum = sandblasting_dull_percentage + satin_dull_percentage;
        return sum <= 100;
      })
      .nullable()
      .transform((_, val) => val === '' ? 0 : Number(val)),
    dull_texture_remark: yup
      .string()
      //.required()
      ,
    enamel_percentage: yup
      .number()
      // .required()
      .typeError("Please enter a valid Enamel")
      .min(0, "Percentage should be between 0 and 100")
      .max(100, "Percentage should be between 0 and 100")
      .nullable()
      .transform((_, val) => val === '' ? 0 : Number(val)),
    enamel_remark: yup
      .string()
      //.required()
      ,
    color_same_selected: yup.boolean(),
    add_more_color: yup
      .number()
      .typeError("Please enter a valid Add more color")
      .min(0, "Percentage should be between 0 and 100")
      .max(100, "Percentage should be between 0 and 100")
      .when("color_same_selected", (color_same_selected, schema) => {
        return color_same_selected ? schema : schema.required();
      })
      .nullable()
      .transform((_, val) => val === '' ? 0 : Number(val)),
    color_remark: yup.string(),
    screw_type: yup.string().required("Please select Screw type"),
    karat: yup.string(),
    order_remark: yup.string(),
  });

  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rhodium_on_stone_percentage: default_user_preference.rhodium_on_stone_percentage,
      rhodium_on_plain_part_percentage: default_user_preference.rhodium_on_plain_part_percentage,
      rhodium_remarks: default_user_preference.rhodium_remarks,
      sandblasting_dull_percentage: default_user_preference.sandblasting_dull_percentage,
      satin_dull_percentage: default_user_preference.satin_dull_percentage,
      dull_texture_remark: default_user_preference.dull_texture_remark,
      enamel_percentage: default_user_preference.enamel_percentage,
      enamel_remark: default_user_preference.enamel_remark,
      color_same_selected: default_user_preference.color_same_selected ? true : false,
      add_more_color: default_user_preference.add_more_color,
      color_remark: default_user_preference.color_remark,
      screw_type: default_user_preference.screw_type,
      karat: addedToCart,
      order_remark: default_user_preference.order_remark,
    },
  });
  useEffect(() => {
    Object.values(errors)
      .slice(0, 1)
      .map((element: any) => {
        toastComponent(element.message, "error");
      });
  }, [errors]);

  useEffect(() => {
    setValue("rhodium_on_stone_percentage", default_user_preference.rhodium_on_stone_percentage);
    setValue("rhodium_on_plain_part_percentage", default_user_preference.rhodium_on_plain_part_percentage);
    setValue("rhodium_remarks", default_user_preference.rhodium_remarks);
    setValue("sandblasting_dull_percentage", default_user_preference.sandblasting_dull_percentage);
    setValue("satin_dull_percentage", default_user_preference.satin_dull_percentage);
    setValue("dull_texture_remark", default_user_preference.dull_texture_remark);
    setValue("enamel_percentage", default_user_preference.enamel_percentage);
    setValue("enamel_remark", default_user_preference.enamel_remark);
    setValue("color_same_selected", default_user_preference.color_same_selected ? true : false);
    setValue("add_more_color", default_user_preference.add_more_color);
    setValue("color_remark", default_user_preference.color_remark);
    setValue("screw_type", default_user_preference.screw_type);
    setValue("karat", addedToCart);
    setValue("order_remark", default_user_preference.order_remark);
  }, [default_user_preference]);

  const watchee = watch();
  const dispatch = useAppDispatch();
  function onSubmit(data: any) {
    dispatch(createleadPreference({ ...data, color_same_selected: data.color_same_selected ? 1 : 0 })).then((res) => {
      if (res.payload.success === true) {
        dispatch(
          placeOrder({
            rhodium_on_stone_percentage: data.rhodium_on_stone_percentage,
            rhodium_on_plain_part_percentage: data.rhodium_on_plain_part_percentage,
            rhodium_remarks: data.rhodium_remarks,
            sandblasting_dull_percentage: data.sandblasting_dull_percentage,
            satin_dull_percentage: data.satin_dull_percentage,
            dull_texture_remark: data.dull_texture_remark,
            enamel_percentage: data.enamel_percentage,
            enamel_remark: data.enamel_remark,
            is_same_as_design: data.color_same_selected,
            additional_color_stone: data.add_more_color,
            additional_color_remark: data.color_remark,
            screw_type: data.screw_type,
            karat: data.karat,
            final_order_remark: data.order_remark,
          })
        ).then((e) => {
          if (e.payload.success === true) {
            toastComponent(e.payload.message);
            setIsView(false);
          }
        });
      }
    });
  }
  useEffect(() => {
    if (isView === false) {
      setTimeout(() => {
        navigate(`/catalogues/${id}`);
      }, 5000);
    }
  }, [isView]);

  useEffect(() => {
    dispatch(getLeadPreference());
  }, []);

  const { id } = useParams();

  return (
    <>
      <div className={isView ? "text-primary min-h-screen container " : "min-h-[90vh] text-primary flex flex-col container"}>
        {isView ? (
          <div className="container min-h-screen ">
            <Link to={`/catalogues/${id}/order-cart`} className="flex flex-row items-center pt-2">
              <img src={backArrow} alt="" className="-rotate-90 h-3 w-3" /> Back
            </Link>
            <div className="sm:float-right mt-3 sm:mt-0">
              <span className="font-bold ">Karat</span>
              <div className="flex flex-row w-full gap-6">
                {[14, 18, 20, 22].map((i) => (
                  <div className="pt-3 cursor-not-allowed" key={nanoid()}>
                    <span className={`border-secondary border py-1 px-1  rounded-md  ${i === addedToCart ? "bg-secondary text-primary" : " text-secondary"}`}>{i}</span>
                  </div>
                ))}
              </div>
            </div>
            <form className="my-[40px]" onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2">
                <SelectComponent
                  cataSelect={false}
                  inputRef="screw_type"
                  data={screwTypeArr}
                  asterisk={true}
                  placeHolder="Bombay Post with Screw"
                  label="Screw Type"
                  control={control}
                />
              </div>
              <br/>
              <p className="font-bold  text-primary text-lg">Rhodium %</p>
              <div className=" flex-col sm:flex-row flex gap-12">
                <InputComponent placeHolder="Rhodium on Stones" label="Rhodium on Stones" inputRef="rhodium_on_stone_percentage" register={register} />
                <InputComponent placeHolder="Rhodium on Plain Part" label="Rhodium on Plain Part" inputRef="rhodium_on_plain_part_percentage" register={register} />
              </div>
              <h4 className="py-2">These % indicates the % of pieces you require Rhodium on.</h4>
              <div>
                <InputComponent placeHolder="Type here..." label="Rhodium Remarks" inputRef="rhodium_remarks" register={register} />
              </div>
              <h4 className="py-2 font-bold text-lg ">Dull Texture %</h4>
              <div className=" flex-col sm:flex-row flex gap-12">
                <div className="flex w-full gap-7">
                  <InputComponent placeHolder="Sandblasting Dull" label="Sandblasting Dull" inputRef="sandblasting_dull_percentage" register={register} />
                  <img src="/image.svg" alt="" />
                </div>
                <div className="flex w-full gap-7">
                  <InputComponent placeHolder="Satin Dull" label="Satin Dull" inputRef="satin_dull_percentage" register={register} />
                  <img src="/image.svg" alt="" />
                </div>
              </div>
              <h4 className="py-2 ">These % indicates the % of pieces you require Texture on.</h4>
              <div className="py-2">
                <InputComponent placeHolder="Type here" label="Dull Texture Remark" inputRef="dull_texture_remark" register={register} />
              </div>
              <div className="py-2 flex w-full gap-7">
                <InputComponent placeHolder="" label="Enamel %" inputRef="enamel_percentage" register={register} />
              </div>
              <h4 className="py-2">These % indicates the % of pieces you require Texture on.</h4>
              <div className="py-2">
                <InputComponent placeHolder="Type here" label="Enamel Remark" inputRef="enamel_remark" register={register} />
              </div>
              <h4 className="font-bold py-2">Color Stone %</h4>
              <div className=" flex-col sm:flex-row gap-3 flex w-full">
                <CheckBoxComponent Label="Same as Design Selected" inputRef="color_same_selected" register={register} />
                <InputComponent placeHolder="" label="Want to add some more color stone" inputRef="add_more_color" register={register} isDisabled={watchee.color_same_selected} />
              </div>
              <h1 className="py-2">These % indicates the % of pieces you require Enamelling on.</h1>
              <div className="py-2">
                <InputComponent placeHolder="Type here" label="Color Stone Remark" inputRef="color_remark" register={register} />
              </div>
              
              <div className="py-2">
                <InputComponent placeHolder="Type here" label="Final Order Remarks" inputRef="order_remark" register={register} />
              </div>
              <div className="py-4 flex w-full gap-7 justify-between">
                <ButtonComponent CTA="Cancel" variant="primary" onClick={() => navigate(`/catalogues/${id}/order-cart`)} />
                <ButtonComponent CTA="Submit & Place Order" buttonType="submit" />
              </div>
            </form>
          </div>
        ) : (
          <div className="h-full m-auto">
            <div className="flex justify-center ">
              <img src="/order placed image.png" alt="empty cart" />
            </div>
            <div className="text-center w-full  ">
              <h1 className="my-6 font-bold">Congratulations, Your order has been placed!</h1>
              <p className="my-6">Our executive will contact you asap.</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserCatalogueForm;
