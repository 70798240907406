import { useEffect, useState } from "react";

type Props = {
  CTA: string;
  variant?: string;
  onClick?: any;
  buttonType?: "submit" | "reset" | "button";
};

const ButtonComponent = ({ CTA = "button", variant="secondary", onClick = () => {}, buttonType = "button" }: Props) => {
  const [color, setcolor] = useState("bg-primary text-secondary");
  useEffect(() => {
    switch (variant) {
      case "secondary":
        setcolor("bg-secondary text-primary");
        break;
      case "danger":
        setcolor("bg-utils-danger text-primary");
        break;
      case "sold-out":
        setcolor("bg-utils-sold-out text-primary");
        break;
      default:
        setcolor("bg-primary text-secondary");
        break;
    }
  }, [variant]);

  return (
    <>
      <button type={buttonType} onClick={() => onClick()} className={`py-[12px] font-bold rounded-[10px] w-full px-4 cursor-pointer focus:outline-none ${color}     `}>
        {CTA}
      </button>
    </>
  );
};

export default ButtonComponent;
