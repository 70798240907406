import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/api";

export const getAllCart = createAsyncThunk("leadUserCart/getAllCart", async () => {
  try {
    const res = await axiosClient.get("/api/cartdesign/lead-cart-details");
    return res.data;
  } catch (err) {
    console.log(err);
  }
});

export const addToCart = createAsyncThunk("leadUserCart/addToCart", async (data: any) => {
  try {
    const res = await axiosClient.post("/api/cartdesign/lead-user-cart", data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
});

export const getLeadPreference = createAsyncThunk("", async (): Promise<any> => {
  try {
    const verify = await axiosClient.get(`/api/userpreference/lead-preference`);
    return verify.data;
  } catch (err) {
    console.log(err);
  }
});

export const createleadPreference = createAsyncThunk("leadUserCart/createleadPreference", async (data: any): Promise<any> => {
  try {
    const verify = await axiosClient.post(`/api/userpreference/create-lead-preference`, data);
    return verify.data;
  } catch (err) {
    console.log(err);
  }
});

export const removeCartData = createAsyncThunk("leadUserCart/removeCart", async (data: any): Promise<any> => {
  try {
    const verify = await axiosClient.post(`/api/cartdesign/lead-cart-destroy`, data);
    return verify.data;
  } catch (err) {
    console.log(err);
  }
});
export const placeOrder = createAsyncThunk("leadUserCart/placeOrder", async (data: any): Promise<any> => {
  try {
    const verify = await axiosClient.post(`/api/order/add-cart-order/lead`, data);
    return verify.data;
  } catch (err) {
    console.log(err);
  }
});

const initialState: leadUserCart = {
  summary: {
    total_qty: 0,
    total_gross_weight: 0,
    total_net_weight: 0,
  },
  row: [],
  add_cart: [
    {
      design_id: 11341,
      category_id: 82,
      karat: 20,
      quantity: 11,
    },
  ],
  default_user_preference: {
    id: null,
    user_id: null,
    lead_user_id: null,
    distributor_id: null,
    retailer_id: null,
    rhodium_on_stone_percentage: null,
    rhodium_on_plain_part_percentage: null,
    rhodium_remarks: "",
    sandblasting_dull_percentage: null,
    satin_dull_percentage: null,
    dull_texture_remark: "",
    enamel_percentage: null,
    enamel_remark: "",
    color_same_selected: null,
    add_more_color: null,
    color_remark: "",
    screw_type: null,
    order_remark: "",
    karat: null,
    deleted_at: null,
  },
};

const leadUserCart = createSlice({
  name: "leadUserCart",
  initialState,
  reducers: {
    addQuantityCart: (state, { payload }) => {
      return {
        ...state,
        add_cart: [...state.add_cart, ...payload],
      };
    },
    updateCart: (state, { payload }) => {
      const updatedCart = payload;
      const index = state.add_cart.findIndex((product: any) => product.design_id === updatedCart.design_id);
      if (index !== -1) {
        state.add_cart[index] = updatedCart;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllCart.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getAllCart.fulfilled, (state, { payload }) => {
        if (!Array.isArray(payload.data)) {
          return {
            ...state,
            status: "success",
            summary: payload?.data?.summary,
            row: payload?.data?.row,
          };
        } else {
          return {
            ...state,
            summary: {
              total_qty: 0,
              total_gross_weight: 0,
              total_net_weight: 0,
            },
            row: [],
          };
        }
      })
      .addCase(getAllCart.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(addToCart.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(addToCart.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
          // addtoCart: payload.data,
        };
      })
      .addCase(addToCart.rejected, (state) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(createleadPreference.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(createleadPreference.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
        };
      })
      .addCase(createleadPreference.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(removeCartData.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(removeCartData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
        };
      })
      .addCase(removeCartData.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(placeOrder.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(placeOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
        };
      })
      .addCase(placeOrder.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(getLeadPreference.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getLeadPreference.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
          default_user_preference: {
            id: payload.data?.id,
            user_id: payload.data?.user_id,
            lead_user_id: payload.data?.lead_user_id,
            distributor_id: payload.data?.distributor_id,
            retailer_id: payload.data?.retailer_id,
            rhodium_on_stone_percentage: payload.data?.rhodium_on_plain_part_percentage,
            rhodium_on_plain_part_percentage: payload.data?.rhodium_on_stone_percentage,
            rhodium_remarks: payload.data?.rhodium_remarks,
            sandblasting_dull_percentage: payload.data?.sandblasting_dull_percentage,
            satin_dull_percentage: payload.data?.satin_dull_percentage,
            dull_texture_remark: payload.data?.dull_texture_remark,
            enamel_percentage: payload.data?.enamel_percentage,
            enamel_remark: payload.data?.enamel_remark,
            color_same_selected: payload.data?.color_same_selected,
            add_more_color: payload.data?.add_more_color,
            color_remark: payload.data?.color_remark,
            screw_type: payload.data?.screw_type,
            order_remark: payload.data?.order_remark,
            karat: payload.data?.karat,
            deleted_at: payload.data?.deleted_at,
          },
        };
      })
      .addCase(getLeadPreference.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      });
  },
});

export const { addQuantityCart, updateCart } = leadUserCart.actions;

export default leadUserCart.reducer;
