import { useState } from "react";
import DistributoreTableComp from "./DistributoreTableComp";

type Props = {
  name: string;
  total_qty: number;
  total_net_weight: number;
  deleteModal?: Function;
  list: any[];
};

const DistributoreAcoordioinComp = ({ name, total_qty, total_net_weight, deleteModal, list }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col my-8 rounded-lg justify-between border border-utils-line w-full">
      <div className="flex  w-full  p-7 justify-between font-bold text-xl cursor-pointer" onClick={() => setIsOpen((prev) => !prev)}>
        <h4 className="text-sm sm:text-lg">{name}</h4>
        <div className="flex flex-row">
          <h4 className="text-sm sm:text-lg">
            {total_qty} pcs| {total_net_weight} gm
          </h4>
          <img src="/dropdown.svg" alt="dropdwon" className="px-2" />
        </div>
      </div>

      {isOpen === true ? (
        <div className="overflow-auto hide-scroll">
          <DistributoreTableComp list={list} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DistributoreAcoordioinComp;
