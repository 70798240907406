import { nanoid } from "nanoid";
import React, { useState } from "react";

type Props = {
  ComponentList: any[];
  TabIndex: string[];
};
const TabComponent = ({ ComponentList, TabIndex }: Props) => {
  const [index, setIndex] = useState<any>(0);
  return (
    <>
      <ul className="Tabs flex flex-col h-full">
        {TabIndex.map((i, tabindex) => (
          <li key={nanoid()} className={`text-base px-2 rounded-sm ${tabindex === index ? "bg-secondary" : ""}`} onClick={() => setIndex(tabindex)}>
            {i}
          </li>
        ))}
      </ul>
      <div className="border-l p-2 w-full m-auto">{ComponentList.map((Component, ComponentIndex) => (ComponentIndex === index ? <Component postionFilter={""} /> : null))}</div>
    </>
  );
};

export default TabComponent;
