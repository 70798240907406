export const FooterRoutes = [
  {
    officeType: "Corporate Office",
    city: "Rajkot",
    contactInfo: [
      {
        type: "address",
        contact:
          "VK Jewels Private Limited, Shri Building, Madhuvanpark Main Road, Opposite Blossom School, Behind Kuvadava Road, Rajkot, Gujarat -360003, India.",
        icon: "/location.svg",
      },
      {
        type: "email",
        email: "connect@vkjewels.co",
        icon: "/email.svg",
      },
      {
        type: "phone",
        tel: "+91 72850 03200",
        icon: "/call.svg",
      },
    ],
  },
  {
    officeType: "Branch Office",
    city: "Mumbai",
    contactInfo: [
      {
        type: "address",
        contact:
          "  VK jewels Private Limited, Office No. 410B, 4th Floor Glitz Mall, Vitthalwadi Lane, Nr. Kalbadevi, Mumbai, Maharashtra - 400002,India.",
        icon: "/location.svg",
      },
      {
        type: "email",
        email: "mumbai@vkjewels.co",
        icon: "/email.svg",
      },
      {
        type: "phone",
        tel: "+91 22 6268 8132",
        icon: "/call.svg",
      },
    ],
  },
];
