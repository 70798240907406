import {  createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../../api/api";

export const designListForLead = createAsyncThunk("leadUser/designList", async (data: any) => {
  try {
    const res = await axiosClient.post(`/api/catalogue/design-list/${data.payload.catalogue_id}`, data.payload);
    return { res: res.data, isInfiniteScroll: data.isInfiniteScroll, currpage : data.payload.page };
  } catch (err) {
    console.log(err);
  }
});

export const designDetailsForLead = createAsyncThunk("leadUser/designDetails", async (data: any) => {
  try {
    const res = await axiosClient.get(`/api/catalogue/design-details/${data.design}?karat=${data.karat}`);
    return res.data;
  } catch (err) {}
});

export const getCategoryList = createAsyncThunk("leadUser/categoryList", async (id: string | undefined) => {
  try {
    const res = await axiosClient.get(`/api/catalogue/category-filter/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }

});

const initialState: LeadUserCatalogue = {
  searchFilter: "",
  status: "success",
  catalogueName: "",
  count: 0,
  totalitems : 0,
  categoryList: [],
  designListPage: 1,
  filtering: false,
  hasMore: true,
  designList: [],
  designDetails: {
    id: 0,
    variant_number: "",
    variant_attribute: "",
    category_id: 0,
    remark: "",
    collection: [""],
    size: "",
    Category: {
      category_name: "",
      id: 0,
    },
    design_weights: [
      {
        karat: [],
        gross_weight: "",
        net_weight: "",
        stone_weight: "",
        stone_difference: "",
      },
    ],
    CartData: [],
    design_molds: [
      {
        id: 0,
        design_mold_stocks: [
          {
            id: 0,
            design_mold_id: 0,
            stock_name_id: 0,
            stone_pcs: 0,
            weight: "",
            stone_price: "",
            stone_color: "",
            bagging: null,
            type_of_setting: null,
            bagging_id: 1,
            type_of_setting_id: 0,
            deleted_at: null,
            created_at: "",
            updated_at: "",
            stock_name: {
              id: 0,
              weight: "",
              purity: null,
              stock_name_id: 0,
              stock_code: "",
              stock_name_code: {
                id: 0,
                stock_name: "",
                stock_type: 0,
                stock_group: {
                  group_name: "",
                },
              },
            },
            stone: 1,
            actual_weight: "",
            weightToShow: "",
            amount: 0,
          },
        ],
      },
    ],
    image_files: [
      {
        image_file: "",
        image_flag: 0,
        image_file_name: "",
      },
      {
        image_file: "",
        image_flag: 0,
        image_file_name: "",
      },
    ],
    MainSizeCombination: [],
    ParentSizeCombination: null,
    netWeightToBeIncluded: "",
    default_order_pieces: 0,
    sizeData: {
      parent_design_id: "",
      child_design_id: "",
      ParentVariantSize: {
        variant_number: "",
        size: "",
        image_files: [],
      },
    },
  },
  categoryid: [],
  karat: [],
  karatid : ['22'],
};

const leadUserCatalogue = createSlice({
  name: "leadUser",
  initialState,
  reducers: {
    searchFilters: (state, action) => {
      return {
        ...state,
        searchFilter: action.payload,
      };
    },
    updateCategoryId: (state, action) => {
      return {
        ...state,
        categoryid: action.payload,
      };
    },
    addItem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        categoryid: [...state.categoryid, Number(action.payload.id)], // add item to the array
      };
    },
    removeItem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        categoryid: state.categoryid.filter((item) => item !== Number(action.payload.id)), // remove item from the array by filtering
      };
    },
    addKaratCattem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        karatid: [...state.karatid, action.payload.id], // add item to the array
      };
    },
    removeKaratCatItem: (state, action) => {
      return {
        ...state,
        filtering  : true,
        karatid: state.karatid.filter((item) => item !== action.payload.id), // remove item from the array by filtering
      };
    },
    updatekarat: (state, action) => {
      return {
        ...state,
        karat: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCategoryList.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getCategoryList.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
          categoryList: payload.data.category,
          filtering : false,
          categoryid: payload.data.category.map((item: any) => item.category_id),
          karat : payload.data.karat,
          // karatid : payload.data.karat.map((item: any) => item.name),
        };
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(designListForLead.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(designListForLead.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
          hasMore: payload?.res.data.is_next_page,
          catalogueName: payload?.res.data.name,
          count: payload?.res.data.count,
          totalitems : payload?.res.data.total_items,
          designListPage: payload?.currpage,
          designList: payload?.res.data.rows,
        };
      })
      .addCase(designListForLead.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(designDetailsForLead.pending, (state, action) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(designDetailsForLead.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: "success",
          filtering : false,
          designDetails: payload.design,
        };
      })
      .addCase(designDetailsForLead.rejected, (state, action) => {
        return {
          ...state,
          loading: "pending",
        };
      });
  },
});

export const {removeKaratCatItem,addKaratCattem, addItem, removeItem, updatekarat, searchFilters } = leadUserCatalogue.actions;

export default leadUserCatalogue.reducer;
