import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../../api/api";

export const verifyLogin = createAsyncThunk("userAuth/verify-login", async (id: any): Promise<any> => {
  try {
    const login = await axiosClient.get(`/api/catalogue/checkCatalogue/${id}`);
    return login.data;
  } catch (err) {
    console.log(err);
  }
});

export const getCountries = createAsyncThunk("userAuth/getCountries", async () => {
  try {
    const countries = await axiosClient.get(`/api/country`);
    return countries.data.data;
  } catch (error) {
    console.log(error);
  }
});

export const getStates = createAsyncThunk("userAuth/getStates", async (id: number) => {
  const state = await axiosClient.get(`/api/country/state/${id}`);
  return state.data.data;
});

export const getCities = createAsyncThunk("userAuth/getCities", async (id: number) => {
  const city = await axiosClient.get(`/api/country/city/${id}`);
  return city.data.data;
});
export const verifyMobile = createAsyncThunk("userAuth/verify-mobile", async (data: any): Promise<any> => {
  const verify = await axiosClient.post(`api/usermaster/catalogue/verify-user`, data);
  return verify.data;
});
export const loginAPI = createAsyncThunk("userAuth/login", async (data: any): Promise<any> => {
  const login = await axiosClient.post(`/api/usermaster/login-user`, data);
  return login.data;
});
export const registerUser = createAsyncThunk("userAuth/signup", async (data: any): Promise<any> => {
  const login = await axiosClient.post(`api/usermaster/catalogue/signup`, data);
  return login.data;
});

export const logoutUser = createAsyncThunk("userAuth/logout", async () => {
  const login = await axiosClient.post(`api/usermaster/catalogue/logout`);
  return login.data;
});
export const leadProfile = createAsyncThunk("distributor/leadProfile", async (id: any): Promise<any> => {
  try {
    const leadProfile = await axiosClient.get(`/api/catalogue/lead-profile/${id}`);
    return leadProfile.data.data;
  } catch (err) {
    console.log(err);
  }
});

const initialState: userAuth = {
  loginVerfiyMess: "",
  isSticky: false,
  name: "Rugved",
  status: "success",
  country_id: 1,
  stateId: 12,
  cityId: 0,
  gender : [{id: 0, name: "Male"}, {id: 1, name: "Female"}],
  genderId : "",
  countries: [
    {
      id: 1,
      name: "",
      shortname: "",
      phonecode: "",
    },
  ],
  states: [
    {
      id: 12,
      name: "Gujarat",
      gst_code: "24",
    },
  ],
  cities: [
    {
      id: 1,
      name: "",
    },
  ],
  mobileNumber: "",
  countryCode: "+91",
  OTPSent: false,
};

const userAuth = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setIsSticky: (state, action) => {
      return {
        ...state,
        isSticky: action.payload,
      };
    },
    setMobileNumber: (state, action) => {
      return {
        ...state,
        mobileNumber: action.payload,
      };
    },
    setCountryCode: (state, action) => {
      return {
        ...state,
        countryCode: action.payload.countryCode,
        country_id: action.payload.id,
      };
    },
    setStateCode: (state, action) => {
      return {
        ...state,
        stateId: action.payload,
      };
    },
    setCityCode: (state, action) => {
      return {
        ...state,
        cityId: action.payload,
      };
    },
    setOTPstatus: (state, action) => {
      return {
        ...state,
        OTPSent: action.payload,
      };
    },
    setGender : (state, action) => {
      return {
        ...state,
        genderId : action.payload
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(verifyLogin.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(verifyLogin.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loginVerfiyMess: payload.message,
          loading: false,
        };
      })
      .addCase(verifyLogin.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getCountries.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        return {
          ...state,
          countries: payload,
          loading: false,
        };
      })
      .addCase(getCountries.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getStates.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getStates.fulfilled, (state, { payload }) => {
        return {
          ...state,
          states: payload,
          loading: false,
        };
      })
      .addCase(getStates.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getCities.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        return {
          ...state,
          cities: payload,
          loading: false,
        };
      })
      .addCase(getCities.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(verifyMobile.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(verifyMobile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          OTPSent: payload.success ? true : false,
        };
      })
      .addCase(verifyMobile.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(leadProfile.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(leadProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(leadProfile.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(loginAPI.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(loginAPI.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(loginAPI.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export const {setGender, setMobileNumber, setCountryCode, setOTPstatus, setStateCode, setCityCode, setIsSticky } = userAuth.actions;

export default userAuth.reducer;
