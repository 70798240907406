import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

type Props = {
  children: React.ReactNode;
};

const PublicRoutes = ({ children }: Props) => {
  const [cookie, setCookie] = useCookies(["authToken", "refreshToken", "type"]);
  const type = cookie.type;
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");


  switch (type) {
    case "1":
      return <>{!cookie.authToken ? <div>{children}</div> : <Navigate to={`/distributor/${id}`} />}</>;
    case "0":
      return <>{!cookie.authToken ? <div>{children}</div> : <Navigate to={`/catalogues/${id}`} />}</>;
    default:
      return <>{children}</>;
  }
};

export default PublicRoutes;
