import { useEffect, useRef, useState } from "react";
import { distributorDesignList } from "../../redux/features/distributoreCatalogue/distributoreCatalogue";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useParams } from "react-router-dom";
import { DistributoreCategoryFilter } from "../../container/DistributorCategoryFiltes";
import DistributorCardComponent from "../../components/DistributorCardComponent";
import { nanoid } from "nanoid";
import InfiniteScroll from "react-infinite-scroll-component";
import VKjLoader from "../../assets/Logo/VKJLoader.gif";
import TagComponent from "../../components/TagComponent";
import ModalComponent from "../../components/ModalComponent";
import DistibutorFilterForm from "../../container/DistibutorFilterForm";
import { getAllCart } from "../../redux/features/distributoreCart/distributoreCart";
import DistributorCartButton from "../../components/DistributorCartButton";
import { DistributorKaratFilter } from "../../container/DistributorKaratFilter";
import Pagination from "react-js-pagination";

type Props = {};

const DistributorCatalogue = (props: Props) => {
  const dispatch = useAppDispatch();
  const ModalRef = useRef<HTMLDivElement | null>(null);
  const [filterView, setfilterView] = useState<boolean>(false);
  const karatIds = useAppSelector((state) => state.distributoreCatalogue.karatIds);
  const categoryIds = useAppSelector((state) => state.distributoreCatalogue.categoryIds);
  const catalugeName = useAppSelector((state) => state.distributoreCatalogue.catalaugename);
  const catalugeitemes = useAppSelector((state) => state.distributoreCatalogue.catalugeitemes);
  const page = useAppSelector((state) => state.distributoreCatalogue.page);
  const distributorDesing = useAppSelector((state) => state.distributoreCatalogue.rows);
  const hasmore = useAppSelector((state) => state.distributoreCatalogue.hasmore);
  const CategoryList = useAppSelector((state) => state.distributoreCatalogue.category);
  const searchFilter = useAppSelector((state) => state.distributoreCatalogue.searchFilter);
  const tcount = useAppSelector((state) => state.distributoreCatalogue.totalcount);
  const filtering = useAppSelector((state) => state.distributoreCatalogue.filtering);

  const { id } = useParams();

  const fetchMore = async (pagenum : any) => {
    if (categoryIds.length !== 0 && karatIds.length !== 0) {
      window.scrollTo(0, 0);
      dispatch(
        distributorDesignList({
          payload: {
            page: pagenum,
            karat: karatIds,
            categoryList: categoryIds,
            distributor_catalogue_id: id,
          },
          // isInfiniteScroll: true,
        })
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getAllCart());
  }, []);

  useEffect(() => {
    console.log()
    if (categoryIds.length !== 0 && karatIds.length !== 0) {
      if (searchFilter === "") {
        dispatch(
          distributorDesignList({
            payload: {
              page: filtering ? 1 : page,
              karat: karatIds,
              categoryList: categoryIds,
              distributor_catalogue_id: id,
            },
            // isInfiniteScroll: false,
          })
        );
      }
    }
  }, [karatIds, categoryIds, searchFilter]);

  return (
    <>
      {filterView ? (
        <ModalComponent setShow={setfilterView} show={filterView}>
          <DistibutorFilterForm ref={ModalRef} closeForm={setfilterView} />
        </ModalComponent>
      ) : null}
      <div className="container">
        <div className="min-h-fit">
          <div className="flex my-[30px] justify-between">
            <div className="title">
              <h4 className="text-xl md:text-2xl text-primary font-bold">{catalugeName}</h4>
              <h6 className="text-primary">Total {tcount} items</h6>
            </div>
            <DistributorCartButton />
          </div>
          <div className="flex sm:hidden gap-3 flex-row overflow-auto hide-scroll w-auto items-center" style={{alignItems : 'flex-end'}}>
            <TagComponent
              icon="/icons8-filter-24.png"
              CTA="Filter"
              onClick={() => {
                setfilterView(true);
              }}
            />
            <DistributorKaratFilter mobileViewDis={true}/>
            {/* <TagComponent
              CTA="All"
              onClick={() => {
                setfilterView(true);
              }}
            />
            {CategoryList.map((i) => (
              <TagComponent
                CTA={i.name}
                onClick={() => {
                  setfilterView(true);
                }}
                key={nanoid()}
              />
            ))} */}
          </div>
          <div className="grid grid-cols-12 gap-2">
            <div className="hidden lg:block col-span-3 xl:col-span-2">
              <div className="shadow-menu  rounded-[10px] ">
                <div className="p-8">
                  <h3 className="text-base font-bold">Categories</h3>
                  <ul className="cursor-pointer pt-5">
                    <DistributoreCategoryFilter />
                  </ul>
                </div>
              </div>
              <div className="shadow-menu my-[30px] rounded-[10px]">
                <div className="p-8 ">
                  <h3 className="text-base font-bold">Karat</h3>
                  <DistributorKaratFilter />
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-9 xl:col-span-10">
              {/* <InfiniteScroll
                className="col-span-12 lg:col-span-10 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-7"
                dataLength={distributorDesing?.length}
                hasMore={hasmore}
                loader={
                  <>
                    <img className="" src={VKjLoader} alt="loader" />
                  </>
                }
                next={() => {
                  fetchMore();
                }}
              > */}
              <div className="grid grid-cols-12 gap-2">
                {distributorDesing.map((i) => (
                  <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3" key={nanoid()}>
                    <DistributorCardComponent
                      cartDetails={i.CartDesignInfo}
                      link={`/distributor/${id}/${i?.variant_number}/${i.id}`}
                      net_weight={i.net_weight}
                      img={i.designs.image_files[0].ImageURL}
                      varient={i?.variant_number}
                      available_stock={i.available_stock}
                      categoryId={i.id}
                      Karat={i.karat}
                      isSoldOut={i.is_sold}
                    />
                  </div>
                ))}
              </div>
                 
              {/* </InfiniteScroll> */}
              {distributorDesing.length === 0 && <p className="text-center font-bold">No Design available</p>}
              <div className="pagination_container">
              <Pagination
                activePage={page}
                itemsCountPerPage={20}
                totalItemsCount={tcount}
                pageRangeDisplayed={5}
                onChange={(e)=>fetchMore(e)}
              />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </div>
      </div>
    </>
  );
};

export default DistributorCatalogue;
