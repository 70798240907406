type Props = {
  label: string;
  placeHolder: string;
  asterisk?: boolean;
  register?: Function;
  inputRef?: string;
  isDisabled?: boolean;
};

const InputComponent = ({ label, placeHolder, asterisk, register = () => {}, inputRef = "val", isDisabled = false }: Props) => {
  return (
    <div className="flex flex-col w-full">
      <label className="text-base font-bold text-primary" htmlFor="">
        {asterisk ? (
          <>
            {label}
            <span className="text-red-500"> *</span>
          </>
        ) : (
          label
        )}
      </label>
      <div className="flex justify-between border-b-[1px] border-b-[#CCCCCC] text-base">
        <input className={`w-full outline-none ${isDisabled ? "bg-slate-100" : ""}`} {...register(inputRef)} placeholder={placeHolder} type="text" disabled={isDisabled} />
        {!asterisk ? <span>%</span> : <></>}
      </div>
    </div>
  );
};

export default InputComponent;
