import { GroupBase, SelectComponentsConfig, SelectOptionActionMeta, StylesConfig } from "react-select";

type MyOptionType = {
  name: string;
  id: string;
};

type IsMulti = false;
//StylesConfig<state, false, GroupBase<state>>
export const DropDownStyles: StylesConfig<state, false, GroupBase<state>> = {
  option: (provided, {isFocused, isSelected}) => ({
    ...provided,
    minHeight: "auto",
    maxHeight: "auto",
    padding: "10px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.2",
    color: "var(--brand-primary)",
    borderRadius: 0,
    border: "0",
    cursor: "pointer",
    background: isFocused
        ? 'rgba(255, 205, 41, 0.5)'
        : isSelected
            ? 'rgb(255 205 41)'
            : undefined,
  }),
  placeholder: (base, state) => ({
    ...base,
    width: "100%",
    justifyContent: "center",
    top: state.isFocused ? "12px" : "6px",
    right: "0px",
    padding: "0px",
  }),
  container: (base) => ({
    ...base,
    width: "100%",
    borderBottom: "1px solid #B8B8B8",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: "10px",
    border: "0",
    cursor: "pointer",
    margin: "10px 0 0",
    zIndex: "99",
    boxShadow: "0px 3px 6px rgba(var(--black-rgb), 0.25) !important",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    border: "none",
    color: "var(--brand-primary)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent !important",
    border: "none !important",
    padding: "0",
    minHeight: "25px",
    flexDirection: "row",
    outline: "none !important",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    " &:hover, &:focus ": {
      backgroundColor: "transparent !important",
      border: "none !important;",
      boxShadow: "none !important;",
      outline: "none !important;",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px",
    width: "100% !important",
  }),
  input: (base) => ({
    ...base,
    padding: "10px 0",
    margin: "0px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: " none !important",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: "1.5rem",
    color: "var(--brand-primary)",
  }),
};
