import React from "react";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { addKaratItem, removeKaratItem } from "../redux/features/distributoreCatalogue/distributoreCatalogue";
import { nanoid } from "nanoid";
import { toastComponent } from "../components/ToastComponent";

type Props = {
  postionFilter?: string;
  mobileViewDis?: boolean;
};

export const DistributorKaratFilter : React.FC<Props> = ({ postionFilter = "horizontal" , mobileViewDis}) => {
  const karatIds = useAppSelector((state) => state.distributoreCatalogue.karatIds);
  const dispatch = useAppDispatch();
  const karatList = useAppSelector((state) => state.distributoreCatalogue.karat);

  function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.checked) {
      if (karatIds.length !== 1) {
        dispatch(removeKaratItem({ id: e.target.value }));
      } else {
        toastComponent("Atleast 1 Karat Need");
      }
    } else {
      dispatch(addKaratItem({ id: e.target.value }));
    }
  }

  return (
    <>
    {
      mobileViewDis ? 
      <div className="sm:float-right items-center gap-2 flex sm:mt-0">
      <span className="font-bold ">Karat </span>
      <div className="flex flex-row w-full gap-5">
      {karatList.map((i) => (
        <div className="cursor-not-allowed" key={nanoid()}>
          <label className={`border-secondary border py-1 px-1 rounded-md cursor-pointer  
            ${karatIds.findIndex((element: any) => element === i.name) === -1  ? "text-secondary " : "bg-secondary text-primary"}`}
          >
            <input
              type="checkbox"
              style={{ display: "none" }}
              value={i.name}
              onChange={(e) => handleClick(e)}
              checked={karatIds.findIndex((element: any) => element === i.name) === -1 ? false : true}
            />
            {i.name}
          </label>
        </div>
      ))}
      </div>
    </div>
      :
      <div className={`${postionFilter === "horizontal" ? "flex flex-row gap-4" : "flex flex-col gap-2"} py-4`}>
        <ul className="cursor-pointer ">
      {karatList?.map((i: any) => (
        <li className="my-1" key={nanoid()}>
          <label className="flex gap-1 items-baseline ">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={(e) => handleClick(e)}
              value={i.name}
              checked={karatIds.findIndex((element: any) => element === i.name) === -1 ? false : true}
            />
            <span style={{marginLeft:"5px"}}>{i.name}</span>
          </label>
        </li>
      ))}
        </ul>
    </div>
    }
  </>
  )
};
