import { useCookies } from "react-cookie";
import { useAppSelector } from "../redux/app/hooks";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const DistributorCartButton = (props: Props) => {
  const cart = useAppSelector((state) => state.distributoreCart.summary);
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies, setCookies] = useCookies(["is_readOnly"]);
  const isSticky = useAppSelector((state) => state.userAuth.isSticky);

  return (
    <>
      {cookies.is_readOnly === "0" ? (
        <div
          className={`${
            isSticky ? "top-5" : "bottom-3"
          } fixed lg:relative h-[70px] lg:h-full   py-1 px-1 lg:bottom-auto lg:left-auto left-center-cart-menu  bg-primary rounded-[10px] z-10`}
        >
          <div className="flex items-center min-w-[330px] justify-between text-white">
            <div className="flex px-2">
              <img src="/cart.svg" alt="" />
              <div className="px-4">{cart.total_qty} items</div>
              <span className="w-[2px] bg-white"></span>
              <div className="px-4">{cart.total_net_weight} gm</div>
            </div>
            <div className="text-secondary py-4 px-4 rounded-[10px] hover:text-primary hover:bg-secondary font-bold" onClick={() => navigate(`/distributor/${id}/order-cart`)}>
              View Cart
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DistributorCartButton;
