import React, { useEffect, useState } from "react";
import ButtonComponent from "./ButtonComponent";
import { Link, useParams } from "react-router-dom";
import { addToCart, getAllCart , removeCartData } from "../redux/features/leadUserCart/leadUserCart";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import vkjLogo from "../assets/Logo/vkj logo.svg";
import ModalComponent from "./ModalComponent";
import { toastComponent } from "../components/ToastComponent";
import { designListForLead } from "../redux/features/leadUserCatalogue/leadUserCatalogue";

type Props = {
  cartData: any[];
  img: string;
  varient: string;
  link: string;
  design_id: number;
  category_id: number;
  karat: number;
  weight: string;
  onClick?: () => void;
};

const CardComponent = ({ img, varient, link, design_id, category_id, karat, weight, onClick, cartData }: Props) => {
  const [quanity, setquantity] = useState<string>("0");
  const [state, setState] = useState(true);
  const addedToCart = useAppSelector((state) => state.leadUserCart.row);
  //  const dataaa = useAppSelector((state) => state.leadUserCatalogue);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const karatIds = useAppSelector((state) => state.leadUserCatalogue.karatid);
  const categoryids = useAppSelector((state) => state.leadUserCatalogue.categoryid);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (String(cartData[0]?.quantity) !== "undefined") {
      setquantity(String(cartData[0]?.quantity));
    } else {
      setquantity("0");
    }
  }, [cartData]);

  const RemoveFromtheCart = () => {
    if (Number(quanity) > 0) {
      setquantity((prev) => String(Number(prev) - 1));
      dispatch(
        addToCart({
          add_cart: [
            {
              design_id: design_id,
              category_id: category_id,
              karat: karat,
              quantity: -1,
            },
          ],
        })
      ).then((res) => {
        if (res.payload.success) {
          dispatch(getAllCart());
        }
      });
    } else {
      setState(true);
    }
  };

  const checkKarat = () => {
    if(addedToCart.length > 0){
      const firstKarat = addedToCart[0].list[0].karat
      if(firstKarat === karat){
        return true;
      }else{
        setDeleteModal(true);
        return false;
      }
    }else{
      return true;
    }
  }

  function handleRemoveall() {
    setDeleteModal(false);
    const idArray: any = [];
    addedToCart.forEach((rowItem) => {
      rowItem.list.forEach((listItem) => {
        idArray.push(listItem.id);
      });
    });
    dispatch(removeCartData({ cart_id: idArray })).then((res) => {
      toastComponent(res.payload.message);
      dispatch(getAllCart());
      dispatch(
        designListForLead({
          payload: {
            page: 1,
            karat: karatIds,
            catalogue_id:  `${id}`,
            category_id: categoryids,
            search_from_category: 1,
          },
          // isInfiniteScroll: true,
        })
      );
    });
    setDeleteModal(false);
  }

  const AddToCart = () => {
    if(checkKarat()){
      setState(false);
      setquantity((prev) => String(Number(prev) + 1));
      dispatch(
        addToCart({
          add_cart: [
            {
              design_id: design_id,
              category_id: category_id,
              karat: karat,
              quantity: 1,
            },
          ],
        })
      ).then((res) => {
        if (res.payload.success) {
          dispatch(getAllCart());
        }
      });
    }
  };

  const [imageSrc, setImageSrc] = React.useState(img);

  const handleImageError = () => {
    setImageSrc(vkjLogo);
  };

  useEffect(() => {
    if (Number(quanity) > 0) {
      setState(false);
    }
  }, [quanity]);

  return (
    <>
    {deleteModal ? (
        <ModalComponent setShow={setDeleteModal} show={deleteModal}>
          <div className="flex flex-col h-full">
            <div className="text-primary font-bold px-6 py-4 border-b flex flex-row justify-between">
              Confirm karat change ?
              <h1 className="text-lg font-bold cursor-pointer" onClick={() => setDeleteModal(false)}>
                X
              </h1>
            </div>
            <div className="min-h-[3rem] px-6 py-2">On karat change cart will be Empty ?</div>
            <div className="flex flex-row justify-betwee px-6 gap-7 py-3">
              <ButtonComponent variant="primary" CTA="Cancel" onClick={() => setDeleteModal(false)} />
              <ButtonComponent variant="secondary" CTA="Submit" onClick={() => handleRemoveall()} />
            </div>
          </div>
        </ModalComponent>
      ) : null}
    <div className="shadow-menu rounded-[10px] flex min-h-fit w-full flex-col px-8 py-2">
      <div>
        <Link to={link}>
          <p></p>
          <div className="py-4 ">
            <img className="w-full h-[250px]  object-contain" onError={handleImageError} src={imageSrc} alt={""} />
          </div>
          <p className="text-base">{varient}</p>
          {/* <h4 className="text-2xl font-bold">{weight} gm</h4> */}
          <div className="flex flex-row gap-3 justify-between items-end">
            <h4 className="text-lg font-bold">{weight} gms</h4>
            <h4 className="text-lg ">{karat}K</h4>
          </div>
        </Link>
        {state ? (
          <div className="py-2">
            <ButtonComponent onClick={() => {AddToCart();}} CTA="Add To Cart" variant="secondary" />
          </div>
        ) : (
          <div className="flex gap-4 cursor-pointer justify-between py-2">
            <div onClick={AddToCart} className="bg-secondary p-3 w-1/4 rounded-lg">
              <img className="m-auto" src="/plus.svg" alt="" />
            </div>
            <input
              value={quanity}
              disabled={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setquantity(e.target.value)}
              className="min-h-[50px] rounded-[10px] w-1/2 text-center border"
              type="number"
            />
            <div onClick={() => RemoveFromtheCart()} className="bg-primary-light w-1/4 cursor-pointer rounded-lg flex-center px-3">
              <img src="/minus.svg" alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default CardComponent;
