import React, { useState } from "react";
import ButtonComponent from "./ButtonComponent";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { nanoid } from "nanoid";
import ModalComponent from "./ModalComponent";
import { getAllCart, removeCartData } from "../redux/features/leadUserCart/leadUserCart";
import { toastComponent } from "./ToastComponent";
import vkjLogo from "../assets/Logo/vkj logo.svg";

type Props = {
  list: LeadUserCartDesign[];
  name: string;
  totalQunty: number;
  totalwight: number;
};

const TableComponent = ({ list }: Props) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  const handleDelete = (e: number) => {
    setId(e);
    setDeleteModal((prev: boolean) => !prev);
  };
  const dispatch = useAppDispatch();
  const confirmDelete = (e: number) => {
    dispatch(
      removeCartData({
        cart_id: e,
      })
    ).then((res) => {
      toastComponent(res.payload.message);
      dispatch(getAllCart());
    });
    setDeleteModal((prev: boolean) => !prev);
  };

  return (
    <>
      {deleteModal ? (
        <ModalComponent setShow={setDeleteModal} show={deleteModal}>
          <div className="flex flex-col h-full">
            <div className="text-primary font-bold px-6 py-4 border-b flex flex-row justify-between">
              Remove From the Cart ?
              <h1 className="text-lg font-bold cursor-pointer" onClick={() => setDeleteModal(false)}>
                X
              </h1>
            </div>
            <div className="min-h-[3rem] px-6 py-2">Are you sure for remove from the cart ?</div>
            <div className="flex flex-row justify-betwee px-6 gap-7 py-3">
              <ButtonComponent variant="primary" CTA="Cancel" onClick={() => setDeleteModal(false)} />
              <ButtonComponent variant="secondary" CTA="Submit" onClick={() => confirmDelete(id)} />
            </div>
          </div>
        </ModalComponent>
      ) : null}
      <div className="w-full">
        <table className="table-auto w-[150%] lg:w-full text-center">
          <thead className="md:contents hidden">
            <tr className="bg-primary text-secondary text-sm	sm:text-base ">
              <th>Image</th>
              <th>Design No.</th>
              <th>Karat</th>
              <th>Gross WT</th>
              <th>Net WT</th>
              <th>Pieces</th>
              <th>Total Gross Wt.</th>
              <th>Total Net Wt.</th>
              <th>Action</th>
            </tr>
          </thead>
          <thead className="contents md:hidden">
            <tr className="bg-primary text-secondary text-sm	sm:text-base">
              <th className="w-[20%] md:w-0 px-5">Image</th>
              <th className="w-[20%] md:w-0 px-5">Design No.</th>
              <th className="w-[20%] md:w-0 px-5">Kt</th>
              <th className="w-[20%] md:w-0 px-5">GWT</th>
              <th className="w-[20%] md:w-0 px-5">NWT</th>
              <th className="w-[20%] md:w-0 px-5">PCS</th>
              <th className="w-[20%] md:w-0 px-5">Total GWT</th>
              <th className="w-[20%] md:w-0 px-5">Total NWT</th>
              <th className="w-[20%] md:w-0 px-5">Action</th>
            </tr>
          </thead>
          <tbody className="text-xs sm:text-sm">
            {list.map((i) => (
              <tr key={nanoid()}>
                <td>
                  <img
                    src={i.CartDesignInfo.image_files[0].image_file}
                    alt="cata"
                    className="w-[100px]  mx-auto"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = vkjLogo;
                      currentTarget.className = "w-[60px]  mx-auto";
                    }}
                  />
                </td>
                <td>{i.CartDesignInfo.variant_number}</td>
                <td>{i.karat}</td>
                <td>{i.CartDesignInfo.design_weights[0].gross_weight}</td>
                <td>{i.CartDesignInfo.design_weights[0].net_weight}</td>
                <td className="p-2">
                  {" "}
                  <ButtonComponent CTA={String(i.quantity)} />
                </td>
                <td>{i.CartDesignInfo.design_weights[0].design_wise_total_gross_weight}</td>
                <td>
                  <span> {i.CartDesignInfo.design_weights[0].design_wise_total_net_weight} </span>
                </td>
                <td className="flex justify-center align-middle py-8">
                  {" "}
                  <img onClick={() => handleDelete(i.id)} src="/delete.svg" alt="delete" className="cursor-pointer w-6  md:w-8 " />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableComponent;
