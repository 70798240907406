import { ReactNode, useRef } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

type Props = {
  children: ReactNode;
  ref?: any;
  show: boolean;
  setShow: any;
};

const ModalComponent = ({ children, show, setShow }: Props) => {
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setShow(false));

  return (
    <>
      {show ? (
        <div className="fixed flex justify-center top-0 left-0 items-center bg-black bg-opacity-25 w-screen h-screen z-10">
          <div ref={ref} className="w-[85vw] sm:w-[35vw] md:w-[65vw] lg:w-[35vw] min-h-[20vh] sm:min-h-[15vh] md:min-h-[10vh] opacity-100 bg-white rounded-[10px] ">
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ModalComponent;
