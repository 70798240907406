import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userAuth from "../features/userAuth/userAuth";
import distributoreCatalogue from "../features/distributoreCatalogue/distributoreCatalogue";
import leadUserCatalogue from "../features/leadUserCatalogue/leadUserCatalogue";
import leadUserCart from "../features/leadUserCart/leadUserCart";
import distributoreCart from "../features/distributoreCart/distributoreCart";

export const store = configureStore({
  reducer: {
    userAuth: userAuth,
    distributoreCatalogue: distributoreCatalogue,
    leadUserCatalogue: leadUserCatalogue,
    leadUserCart: leadUserCart,
    distributoreCart:distributoreCart,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
