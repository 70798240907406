import axios from "axios";
import { Cookies } from "react-cookie";
import { toastComponent } from "../components/ToastComponent";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
axiosClient.interceptors.response.use(
  function (response: any) {
    return response;
  },
  async function (error: any) {
    let res = error.response;
    const originalConfig = error.config;
    const cookies = new Cookies();

    if (res.status === 409) {
      toastComponent(res.data.message ? res.data.message :"invalid values" );
    }
    if (res.status === 400) {
      if (cookies.get("type") === "0") {
        if (originalConfig.url === "api/usermaster/catalogue/refreshtoken" && error.response) {
          const Cookiess = new Cookies();
          Cookiess.remove("refreshToken", { path: "/" });
          Cookiess.remove("authToken", { path: "/" });
          window.location.href = `/login?id=${window.location.pathname.split("/")[2]}`;
        }
        if (originalConfig.url.includes("api/catalogue/lead-profile") && error.response) {
          const Cookiess = new Cookies();
          Cookiess.remove("refreshToken", { path: "/" });
          Cookiess.remove("authToken", { path: "/" });
        }
      }
      if (cookies.get("type") === "1") {
        if (originalConfig.url === "api/distributorCatalogue/refreshtoken" && error.response) {
          const Cookiess = new Cookies();
          Cookiess.remove("refreshToken", { path: "/" });
          Cookiess.remove("authToken", { path: "/" });
          Cookiess.remove("is_readOnly", { path: "/" });
          window.location.href = `/?id=${window.location.pathname.split("/")[2]}`;
        }
        if (originalConfig.url.includes("api/distributorCatalogue/distributor-profile") && error.response) {
          const Cookiess = new Cookies();
          Cookiess.remove("refreshToken", { path: "/" });
          Cookiess.remove("authToken", { path: "/" });
          Cookiess.remove("is_readOnly", { path: "/" });
        }
      }
      return res;
    }
    if(res.status === 440){
      if(cookies.get("type") === "0"){ 
        const Cookiess = new Cookies();
        Cookiess.remove("refreshToken", { path: "/" });
        Cookiess.remove("authToken", { path: "/" });
        Cookiess.remove("type", { path: "/" });
        Cookiess.remove("is_readOnly", { path: "/" });
        window.location.href = `/?id=${window.location.pathname.split("/")[2]}`;
      }
      if(cookies.get("type") === "1" ){
        const Cookiess = new Cookies();
        Cookiess.remove("refreshToken", { path: "/" });
        Cookiess.remove("authToken", { path: "/" });
        Cookiess.remove("type", { path: "/" });
        window.location.href = `/login?id=${window.location.pathname.split("/")[2]}`;
      }
    }
    if (res.status === 500) {
      if (cookies.get("type") === "0") {
        if (originalConfig.url === "api/usermaster/catalogue/refreshtoken" && error.response) {
          const Cookiess = new Cookies();
          Cookiess.remove("refreshToken", { path: "/" });
          Cookiess.remove("authToken", { path: "/" });
          Cookiess.remove("type", { path: "/" });
          window.location.href = `/login?id=${window.location.pathname.split("/")[2]}`;
        }
      }
      if (cookies.get("type") === "1") {
        if (originalConfig.url === "api/distributorCatalogue/refreshtoken" && error.response) {
          const Cookiess = new Cookies();
          Cookiess.remove("refreshToken", { path: "/" });
          Cookiess.remove("authToken", { path: "/" });
          Cookiess.remove("type", { path: "/" });
          window.location.href = `/?id=${window.location.pathname.split("/")[2]}`;
        }
      }
    }
    if (res.status === 401) {
      if (originalConfig.url !== "/api/distributorCatalogue/login" && originalConfig.url !== "/api/distributorCatalogue/get-otp" && error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            let res: any;
            
            if (cookies.get("type") === "0") {
              res = await axiosClient.post("api/usermaster/catalogue/refreshtoken", {
                refresh_token: cookies.get("refreshToken"),
              });
            } else if (cookies.get("type") === "1") {
              res = await axiosClient.post("api/distributorCatalogue/refreshtoken", {
                refresh_token: cookies.get("refreshToken"),
              });
            } else {
              cookies.remove("authToken");
              cookies.remove("refreshToken");
              cookies.remove("type");
            }
            const { authToken } = res.data;
            if (authToken) {
              cookies.set("authToken", authToken, { path: "/" });
              originalConfig.headers["Authorization"] = `Bearer ${authToken}`;
            } else {
              cookies.remove("authToken");
              cookies.remove("refreshToken");
              cookies.remove("type");
            }
            return axiosClient(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      } else {
      }
    }
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(
  function (request) {
    if (
      request.url !== "/api/country" &&
      request.url !== "/api/country/state" &&
      request.url !== "/api/country/city" &&
      request.url !== "/api/usermaster/login-user" &&
      request.url !== "/api/usermaster/catalogue/signup" &&
      request.url !== "/api/distributorCatalogue/get-otp" &&
      request.url !== "api/usermaster/catalogue/verify-user"
    ) {
      let cookie = new Cookies();
      // if (token) {
      const token = cookie.get("authToken");
      request.headers.Authorization = `Bearer ${token}`;
      // }
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
